<script lang="ts" setup>import { computed as _computed, ref as _ref } from 'vue';

import { TransitionRoot } from '@headlessui/vue'

const TYPE_COLORS = {
  info: '#00A2F1', // blue-base
  success: '#0F9D58', // green-base
  warning: '#ED7D19', // orange-900
  error: '#CF142B', // red-base
}

withDefaults(defineProps<{
  title?: string
  text?: string
  type?: keyof typeof TYPE_COLORS
  color?: string
  backgroundColor?: string
  hideable?: boolean
  autoCloseAfter?: number | false // In milliseconds
}>(), { type: 'info',hideable: true, })

const slots = useSlots()

const typeColor = _computed(() => TYPE_COLORS[__props.type || 'info'])

const textColor = computed(() => __props.color ?? typeColor.value)

const bgColor = computed(() => __props.backgroundColor ?? typeColor.value)

interface Emit {
  (e: 'update:text', value: string | undefined | null): void
  (e: 'close'): void
}
const emit = defineEmits<Emit>()

let isVisible = _ref(true)
const onClose = () => {
  isVisible.value = false
  emit('close')
}

watch(
  () => [__props.text, slots.default],
  () => isVisible.value = true,
  { immediate: true },
)

onMounted(() => {
  if (__props.autoCloseAfter) {
    setTimeout(() => {
      emit('update:text', undefined)
      onClose()
    }, __props.autoCloseAfter)
  }
})
</script>

<template>
  <TransitionRoot
    :show="isVisible"
    enter="ease-in-out duration-300 transform"
    enterFrom="opacity-0 -translate-y-full"
    enterTo="opacity-100 translate-y-0"
    leave="ease-in-out duration-200 transform"
    leaveFrom="opacity-100 translate-y-0"
    leaveTo="opacity-0 -translate-y-full"
    class="
      border-1 border-solid
      flex items-start justify-between relative g-message
      py-4 px-6 w-full rounded-md
    "
    :class="backgroundColor ? null : `bg-[${bgColor}] bg-opacity-10`"
    :style="{
      color: textColor,
      backgroundColor: backgroundColor ?? ''
    }"
  >
    <div>
      <div v-if="title" class="font-semibold mb-3">{{ title }}</div>
      <slot>
        <span class="whitespace-pre-wrap" v-text="text"/>
      </slot>
    </div>
    <button v-if="hideable" class="m-1 absolute top-4 right-4" @click="onClose">
      <GIcon name="close" :color="color" size="12px"/>
    </button>
  </TransitionRoot>
</template>

<style lang="scss">
.g-message {
  a {
    color: inherit !important;
    text-decoration: underline;
  }
}
</style>
